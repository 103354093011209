.ace-multi-cursor {
  position: absolute;
  pointer-events: auto;
  z-index: 10;
}

.ace-multi-cursor:before {
  content: "";
  width: 6px;
  height: 5px;
  display: block;
  background: inherit;
  margin-left: -2px;
  margin-top: -5px;
}

.ace-multi-cursor-tooltip {
  position: absolute;
  white-space: nowrap;
  color: #FFFFFF;
  text-shadow: 0 0 1px #000000;
  opacity: 1.0;
  font-size: 12px;
  padding: 2px;
  font-family: sans-serif;

  transition: opacity 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -ms-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
}

.ace-multi-selection {
  position: absolute;
  pointer-events: auto;
  z-index: 10;
  opacity: 0.3;
}

.ace-radar-view {
  position: relative;
  min-width: 6px;
}

.ace-radar-view-scroll-indicator {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 4px;
  cursor: pointer;
  border-style: double;
  border-width: 3px;
}

.ace-radar-view-cursor-indicator {
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid black;
}

.ace-radar-view-wrapper {
  position: relative;
  float: left;

  height: 100%;
  width: 6px;

  margin-right: 4px;
}
